<template>
  <div class="welcome-page">
    <div class="title">
      <h4>{{ layouts.data.title }}</h4>
      <div class="ma-2">&nbsp;</div>
    </div>

    <div class="consent-wrapper">
      <div class="consent-content">
        <h5>
          {{ $route.path.includes('consent') ? layouts.data.LASecondTitle : layouts.data.secondTitle }}
        </h5>

        <v-card
          flat
          rounded="lg"
          class="bg-accent my-6"
        >
          <v-card-title>{{ layouts.settings.why[0].title }}</v-card-title>
          <v-card-text>
            <v-row dense>
              <template v-for="(item, itemIndex) in layouts.settings.why[0].items">
                <v-col cols="6" :key="itemIndex">
                  <div class="promotion-item">
                    <v-icon small color="#464C5B">mdi-menu-right</v-icon>
                    <span>{{ item }}</span>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>

        <template v-if="!layouts.data.hasVerifyConsent && assist.tool.isNotEmpty(layouts.data.protocol) && !layouts.data.errorVerify">
          <template v-for="(item, index) in layouts.data.protocol">
            <v-flex
              :key="`phone-${index}`"
              v-if="assist.tool.isNotEmpty(item.extension) && item.extension.isInputPhone"
              class="consent-divider"
            >
              <ValidationObserver ref="observer" v-slot="{ validate, reset }">
                <ValidationProvider
                  name="Phone Number"
                  rules="required|phone"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <v-text-field
                    class="phone-number"
                    label="Phone Number"
                    v-model="layouts.data.homePhone"
                    v-mask="'(###) ###-####'"
                    :error-messages="errors[0]"
                    :disabled="!isWelcomePage"
                  ></v-text-field>
                </ValidationProvider>
              </ValidationObserver>
            </v-flex>
            <v-flex class="d-flex align-center py-1 consent-divider" :key="index">
              <animate-check-box :checked-id="`cbx${index}`" :checked-status="item.check" @change="onClickCheckbox(item)"></animate-check-box>
              <div>
                <span v-html="item.labelPrefix"></span>
                <b class="checkbox-text-highlight consents-prominent" @click="onClickProtocol(item)">{{ item.templateLabel }}</b>
              </div>
            </v-flex>
          </template>
        </template>
      </div>
    </div>

    <v-spacer></v-spacer>

    <div align="right" class="mt-4">
      <web-btn
        :disabled-control="layouts.data.errorVerify || !(layouts.data.hasVerifyConsent || layouts.data.requiredCheckbox)"
        :theme="layouts.data.errorVerify || !(layouts.data.hasVerifyConsent || layouts.data.requiredCheckbox) ? '' : 'primary'"
        :height="50"
        :width="200"
        :loading="layouts.settings.btnLoading"
        @click="onClickContinue"
      >
        Continue
      </web-btn>
    </div>

    <v-dialog
      v-model="layouts.dialog.protocol.display"
      max-width="600px"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 text-center">
          <div
            class="dialog-title"
            v-html="layouts.dialog.protocol.json.templateTitle"
          ></div>
          <v-spacer></v-spacer>
          <v-icon @click="layouts.dialog.protocol.display = false">
            close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div
            class="m-t-20"
            v-html="layouts.dialog.protocol.json.templateContent"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DataType, Tool, Popup } from '@/assets/js/util'
import { OnlineApi, PhoenixConfigApi } from '@/api'
import WebBtn from '@/components/base/WebBtn'
import SYSTEM_ENUM from '@/settings/layout-setting/system/systemEnum'
import AnimateCheckBox from '@/components/base/AnimateCheckBox'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const tool = new Tool()

export default {
  components: {
    AnimateCheckBox,
    WebBtn,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    assist: {
      dataType: DataType,
      tool: tool,
      popup: new Popup()
    },
    layouts: {
      data: {
        title: '',
        secondTitle: 'Let’s confirm some key information about your account to see if you are eligible for a loan!',
        LASecondTitle: 'Prior to finalizing your loan agreement, please carefully review and select the consents provided below.',
        protocol: [],
        hasVerifyConsent: false,
        errorVerify: false,
        requiredCheckbox: false,
        portfolio: {
          iconUrl: ''
        },
        homePhone: ''
      },
      dialog: {
        protocol: {
          display: false,
          json: {}
        }
      },
      settings: {
        why: SYSTEM_ENUM.WHY,
        disabledControl: false,
        btnLoading: false
      }
    },
    isWelcomePage: null
  }),
  watch: {
    'store.getters.getPortfolioJson' (val) {
      if (tool.isNotEmpty(val)) {
        this.layouts.settings.why[0].title = 'Why choose ' + val.name + '?'
        this.layouts.data.title = 'Welcome to ' + val.name + '!'
        this.getProtocolTemplate(val)
      }
    },
    'store.getters.getIsConsent': {
      handler (val) {
        if (tool.isNotEmpty(val) && this.$route.path.includes('consent')) {
          this.layouts.data.hasVerifyConsent = val
        }
      }
    }
  },
  mounted () {
    this.layouts.data.homePhone = this.$store.getters.getConsentPhone

    const portfolioJson = this.store.getters.getPortfolioJson
    if (tool.isNotEmpty(portfolioJson)) {
      this.layouts.settings.why[0].title = 'Why choose ' + portfolioJson.name + '?'
      this.layouts.data.title = 'Welcome to ' + portfolioJson.name + '!'
      this.getProtocolTemplate(portfolioJson)
    }

    this.isWelcomePage = this.$route.path.includes('welcome')
    this.isWelcomePage ? this.getProtocol() : this.layouts.data.hasVerifyConsent = this.$store.getters.getIsConsent
  },
  methods: {
    onClickCheckbox (item) {
      item.check = !item.check
      this.$forceUpdate()
      this.layouts.data.requiredCheckbox = this.layouts.data.protocol.every(item => {
        if (tool.isNotEmpty(item.extension) && item.extension.isOptional) {
          return true
        }
        return item.check
      })
    },
    onClickProtocol (protocol) {
      this.layouts.dialog.protocol.display = true
      this.layouts.dialog.protocol.json = protocol
    },
    getProtocolTemplate (portfolio) {
      const { portfolioId } = portfolio
      PhoenixConfigApi.getConsentTemplate({ portfolioId: portfolioId }, res => {
        if (tool.isNotEmpty(res) && tool.isNotEmpty(res?.templates)) {
          this.layouts.data.protocol = res.templates
          this.layouts.data.protocol.forEach(item => {
            item.check = false
          })
        }
      }, () => {
        this.layouts.data.errorVerify = true
      })
    },
    getProtocol () {
      OnlineApi.listConsentStatus((result) => {
        if (tool.isNotEmpty(result)) {
          this.layouts.data.hasVerifyConsent = result
        }
      }, () => {
        // 点击域名进来没有token,请求失败不让跳转
        this.layouts.data.errorVerify = true
      })
    },
    onClickContinue () {
      if (this.layouts.data.hasVerifyConsent) {
        this.layouts.settings.btnLoading = true
        this.directPage()
        return
      }

      const observer = Array.isArray(this.$refs.observer) ? this.$refs.observer[0] : this.$refs.observer
      if (observer) {
        observer.validate().then((validResult) => {
          if (validResult) {
            this.batchSaveConsents()
          }
        })
      } else {
        this.batchSaveConsents()
      }
    },
    batchSaveConsents () {
      this.layouts.settings.btnLoading = true
      if (this.layouts.data.requiredCheckbox) {
        this.$store.commit('setDocumentUrl', '')
        const consentTemplate = []
        this.layouts.data.protocol.forEach(item => {
          consentTemplate.push({
            consentId: item.consentTemplateId,
            status: item.check ? 1 : 0,
            versionId: item.versionId,
            isOptional: item.extension?.isOptional
          })
        })
        if (this.assist.tool.isEmpty(consentTemplate)) this.recordError()
        OnlineApi.batchSaveConsentStatus({
          consentDataList: consentTemplate,
          loanNo: this.$store.getters.getLoan.loanNo,
          resource: this.isWelcomePage ? null : this.$store.getters.getResource,
          consentPhone: this.layouts.data.homePhone?.replace(/\D/ig, '')
        }, (res) => {
          if (!this.isWelcomePage) {
            this.layouts.settings.btnLoading = false
            this.$store.commit('setDocumentUrl', `${res}?embed=1`)
            this.$store.commit('setIsConsent', true)
            this.$router.push('/loan-agreement')
          }
          this.directPage()
        }, (err) => {
          this.recordError()
          this.layouts.settings.btnLoading = false
          this.assist.popup.popupForError(err.message)
        })
      }
    },
    recordError () {
      const data = {
        protocol: this.layouts.data.protocol,
        checkBoxStatus: this.layouts.data.requiredCheckbox,
        hasVerifyConsent: this.layouts.data.hasVerifyConsent,
        errorVerify: this.layouts.data.errorVerify
      }
      const input = {
        loanNo: this.$store.getters.getLoan.loanNo,
        identity: 'WEB_CONSENT',
        businessData: JSON.stringify(data)
      }
      OnlineApi.recordFailureData(input)
    },
    directPage () {
      this.layouts.settings.btnLoading = false
      if (this.isWelcomePage) {
        // const type = this.$store.getters.getLoan.incompleteScriptType
        const route = this.$store.getters.getHasQualifiedPrincipal
          ? '/disbursement/loan-application'
          : '/guidance'
        this.$router.push(route)
      } else if (this.assist.tool.isNotEmpty(this.$store.getters.getDocumentUrl)) {
        this.$router.push('/loan-agreement')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.welcome-page
  display: flex
  flex-direction: column
  height: 100%
  max-height: 620px

.main-content
  height: 100%

  &-title
    padding: 32px 0px 0px 0px
    font-size: 16px
    font-weight: bold

.bg-accent
  background-color: var(--theme_third_opacity_high) !important
  color: #464C5B

.checkbox-text
  font-size: 14px

  &-highlight
    cursor: pointer
    color: var(--theme_primary)

.dialog-title
  width: calc(100% - 24px)

.checkbox-input
  padding: 0 12px

  &-square
    display: flex
    justify-content: center
    align-items: center
    width: 14px
    height: 14px
    border-radius: 2px
    background-color: #ffffff
    border: 1px solid #464C5B

  &-status
    font-size: 16px

.active
  border: 1px solid #9ca0ab!important

.mt-19
  margin-top: 76px!important

.consent-wrapper
  overflow-y: auto
  &::-webkit-scrollbar
    width: 4px
    height: 10px
    background-color: #f5f5f5
  &::-webkit-scrollbar-thumb
    background-color: #9a9a9a
    border-radius: 32px
  &::-webkit-scrollbar-button
    display: none
    background-color: #ccc
.consent-content
  height: auto

.consent-divider
  border-bottom: 4px solid #FFFFFF
  background-color: #F5F5F5
  &:nth-child(3),
  &:nth-child(4)
    border-radius: 8px
    margin-bottom: 4px
    border-bottom: none
  &:nth-child(5)
    border-top-left-radius: 8px
    border-top-right-radius: 8px
  &:last-child
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px

.phone-number
  margin-left: 42px
  margin-right: 32px
</style>
